.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.containerUE {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 20px;
    gap: 20px;
}
.containerUE > img {
    width: 70px;
    margin: 0;
}
.containerUE > p {
    margin: 0;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 70px; */
}
.contentHome1,
.contentHome2 {
    /* position: absolute; */
    opacity: 0;

    transition: opacity 0.5s ease-in-out;
    animation: fadeInOut 14s infinite;
}
.contentHome2 {
}

.contentHome1 {
    animation-delay: 0s;
}

.contentHome2 {
    animation-delay: 7s;
}

@keyframes fadeInOut {
    0%,
    3.5%,
    46.5%,
    100% {
        opacity: 0;
        display: none;
    }
    7.5%,
    42.5% {
        opacity: 1;
        display: block;
    }
}
